import React, { useEffect } from 'react'
import style from './Footer.module.scss'
import { NavLink, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchConfigs } from '../../redux/config.slice'
import { getValue } from '../../utils/config-value'
function Footer() {
  const dispatch = useDispatch()
  const { configs } = useSelector((state) => state.config)
  useEffect(() => {
    dispatch(fetchConfigs())
  }, [dispatch])
  return (
    <React.Fragment>
      <div className={style.footerBg}>
        <div className={`${style.wrapper} ${style.cover}`}>
          <div className={style.aboutMenu}>
            <div className={style.aboutLink}>
              <div className={style.about}>
                <img src={require('./assets/CASC.png')} alt='casc-logo' />
                <p>บริษัทเเนะเเนวเรียนต่อประเทศจีนเจ้าเเรกๆ ของประเทศ ไทยที่ได้มีประสบการณ์ มากกว่า 30 ปี</p>
              </div>

              <div className={style.link}>
                <p>Quicklinks</p>
                <div className={style.menu}>
                  <NavLink to='/'>Home</NavLink>
                  <NavLink to='/blog'>Blog</NavLink>
                  <NavLink to='/university'>University</NavLink>
                  <NavLink to='/about-us'>About Us</NavLink>
                  <NavLink to='/courses'>Courses</NavLink>
                  <a href='#contact-us'>Contact Us</a>
                </div>
              </div>
              <div className={style.contact}>
                <p>ติดตามได้ที่</p>
                <div className={style.item}>
                  <img src={require('./assets/facebook.png')} alt='facebook' />
                  <a href={getValue('facebook_url', configs)} target='_blank' rel='noreferrer noopener'>
                    {getValue('facebook_page', configs)}
                  </a>
                </div>
                <div className={style.item}>
                  <img src={require('./assets/instagram.png')} alt='facebook' />
                  <a href={getValue('instagram_url', configs)} target='_blank' rel='noreferrer noopener'>
                    {getValue('instagram_id', configs)}
                  </a>
                </div>
              </div>
            </div>

            <div className={style.backToTop}>
              <img
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                }}
                src={require('./assets/Back to top.png')}
                alt='back-to-top'
              />
            </div>
          </div>
          <div className={style.branch}>
            <div className={style.branchCity}>
              <p>สาขากรุงเทพฯ</p>
              <div className={style.icon}>
                <img src={require('./assets/Call.png')} alt='call-icon' />
                <div>02-1131119</div>
              </div>
              <div className={style.icon}>
                <img src={require('./assets/Location.png')} alt='location-icon' />
                <div>
                  ห้อง B203 ชั้น 2 อาคาร B เลขที่ 353,355 ศูนย์การค้า I'm Par ซ.จุฬาฯ 9 ถ.เจริญเมือง
                  แขวงวังใหม่ เขตปทุมวัน กทม. 10330
                </div>
              </div>
            </div>
            <div className={style.branchCity}>
              <p>สาขาเชียงใหม่</p>
              <div className={style.icon}>
                <img src={require('./assets/Call.png')} alt='call-icon' />
                <div>02-1131119</div>
              </div>
              <div className={style.icon}>
                <img src={require('./assets/Location.png')} alt='location-icon' />
                <div>วิทยาลัยนานาชาติ มหาวิทยาลัยแม่โจ้ 63 หมู่ 4 ต.หนองหาน อ.สันทราย จ.เชียงใหม่ 50290</div>
              </div>
            </div>
          </div>
          <div className={style.copyRight}>
            <p>Copyright © 2020. Chinese Abroad Study Center Co.,Ltd. All rights reserved.</p>
            <div className={style.coverPolicy}>
              <Link to='/term-condition'>Terms & Conditions</Link>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer
