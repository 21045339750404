import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const applyUniversity = createAsyncThunk('apply/applyUniversity', async (university) => {
  const applyList = JSON.parse(window.localStorage.getItem('apply.list') || '[]') ?? []
  const hasApply = applyList.find(({ id }) => id === university?.id)
  if (!hasApply) {
    applyList.push(university)
    window.localStorage.setItem('apply.list', JSON.stringify(applyList))
  }
  return applyList
})

export const removeApply = createAsyncThunk('apply/removeUniversity', async (universityId) => {
  let applyList = JSON.parse(window.localStorage.getItem('apply.list') || '[]') ?? []
  applyList = applyList.filter(({ id }) => id !== universityId)
  window.localStorage.setItem('apply.list', JSON.stringify(applyList))
  return applyList
})

export const fetchApply = createAsyncThunk('apply/fetchApply', async () => {
  const applyList = JSON.parse(window.localStorage.getItem('apply.list') || '[]') ?? []
  return applyList
})

export const ApplySlice = createSlice({
  name: 'apply',
  initialState: {
    entities: [],
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  },
  extraReducers: {
    [applyUniversity.fulfilled]: (state, action) => {
      state.entities = action.payload
    },
    [fetchApply.fulfilled]: (state, action) => {
      state.entities = action.payload
    },
  },
})
