import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchProgramslist = createAsyncThunk('programs/fetchProgramList', async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/programs`)
  return res.data
})

export const getProgramByFilter = createAsyncThunk('programs/getProgramByFilter', async (props) => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/v2/university/program-list`, {
    params: { ...props },
  })
  return res.data
})

export const programSlice = createSlice({
  name: 'program',
  initialState: {
    entities: [],
    loading: false,
    currentRequestId: undefined,
    list: [],
    current: {
      name: '',
      courses: [],
      images: [],
      reviews: [],
    },
    totalRecords: 0,
    error: null,
  },
  reducers: {
    applyFilter: (state) => state,
    increment: (state) => state + 1,
    decrement: (state) => state - 1,
    // setCurrent: (state, action) => {
    //   const current = state.entities.find(e => e.id === action.payload.universityId)
    //   if(current) {
    //     state.current = current
    //     return
    //   }
    // }
    // fetchUniversity: async () => {
    //   const res = await axios.get(`${process.env.react_app_api_url}/universities`)
    //   return res.data
    // },
  },
  extraReducers: {
    [fetchProgramslist.fulfilled]: (state, action) => {
      state.entities = action.payload
    },
    [getProgramByFilter.fulfilled]: (state, action) => {
      state.list.splice(action.payload.offset)
      state.list.push(...action.payload.rows)
      state.totalRecords = action.payload.count
      state.loading = false
    },
    [getProgramByFilter.pending]: (state) => {
      state.loading = true
    },
    [getProgramByFilter.rejected]: (state) => {
      state.loading = false
    },
  },
})
