import { Toast } from 'primereact/toast'
import React, { useRef } from 'react'
const AppContext = React.createContext({
  toast: {
    success({ title, message }) {},
  },
})

export default function BaseProvider({ children }) {
  const toastRef = useRef()
  const toast = {
    success: ({ title, message }) => {
      toastRef.current.show({ severity: 'success', summary: title, detail: message })
    },
  }
  const base = {
    toast,
  }
  return (
    <AppContext.Provider value={base}>
      <Toast ref={toastRef} style={{ zIndex: 9999 }} />
      {children}
    </AppContext.Provider>
  )
}
export function useBaseFunction() {
  const context = React.useContext(AppContext)
  if (!context) {
    throw new Error('useBaseFunction must be used within BaseProvider')
  }
  return context
}
