import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const  fetchApplicationProgramUpload = createAsyncThunk(
    'application-program/fetchApplicationProgramUpload',
    async ( applicationId , { getState }) => {
        console.log(`${process.env.REACT_APP_API_URL}/public/v2/programApplication/single?id=${applicationId}`)
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/public/v2/programApplication/single?id=${applicationId}`,
        headers: {
            Authorization: getState().auth.user?.token || '',
        },
      })
      return response.data
    }
  )
  export const applicationProgramUpload = createSlice({
    name: 'applicationProgramUpload',
    initialState: {
      isLoading: false,
      data: {},
    },
    reducers: {
      clearCourse: (state, action) => {
        state.applicationUpload = []
      },
    },
    extraReducers: {
      [fetchApplicationProgramUpload.fulfilled]: (state, action) => {
        state.data = action.payload
      },
    },
  })


//   export const ApplicationProgramUpload = createSlice({
//     name: 'ApplicationProgramUpload',
//     initialState: {
//       entities: [],
//       totalRecords: 0,
//       loading: false,
//       error: null,
//     },
//     extraReducers: {
//       [fetchApplicationProgramUpload.pending]: (state) => {
//         state.loading = true
//       },
//       [fetchApplicationProgramUpload.fulfilled]: (state, action) => {
//         state.entities = action.payload.rows
//         state.totalRecords = action.payload.count
//         state.loading = false
//       },
//       [fetchApplicationProgramUpload.rejected]: (state) => {
//         state.entities = []
//         state.totalRecords = []
//         state.loading = false
//       },
//     },
//   })
  