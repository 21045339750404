import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
export const fetchProductList = createAsyncThunk('product/fetchProductLst', async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/v2/product/list-of-value/`)
  return res.data
})
export const productSlice = createSlice({
  name: 'product',
  initialState: { list: [] },

  extraReducers: (builder) => {
    builder.addCase(fetchProductList.fulfilled, (state, action) => {
      state.list = action.payload
    })
  },
})
