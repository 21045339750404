import { combineReducers } from 'redux'

import { appReducer } from '../App/App.reducer'
import { authReducer } from './auth/Auth.reducer'
// import { modalReducer } from "./reducer_modal/Modal.reducer"

// import { universityReducer } from "../reducer_university/University.reducer"
import { adminSlice } from './Admin.slice'
import { modalSlice } from './Modal.slice'
import { universitySlice } from './University.slice'

import { applicationProgramUpload } from './application-program.slice'
import { applicationSlice } from './Application.slice'
import { ApplySlice } from './apply-university.slice'
import { bannersSlice } from './Banner.slice'
import { billingSlice } from './billing.slice'
import { blogsSlice } from './Blog.slice'
import { configSlice } from './config.slice'
import { countrySlice } from './country.slice'
import { courseSlice } from './Course.slice'
import { lessonSlice } from './lesson.slice'
import { productSlice } from './product.slice'
import { programSlice } from './Programs.slice'
import { reviewsSlice } from './Review.slice'
import { transactionSlice } from './transaction.slice'

export const rootReducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  // modal: modalReducer,
  lesson: lessonSlice.reducer,
  billing: billingSlice.reducer,
  blogs: blogsSlice.reducer,
  reviews: reviewsSlice.reducer,
  admin: adminSlice.reducer,
  modal: modalSlice.reducer,
  university: universitySlice.reducer,
  banner: bannersSlice.reducer,
  course: courseSlice.reducer,
  application: applicationSlice.reducer,
  country: countrySlice.reducer,
  config: configSlice.reducer,
  apply: ApplySlice.reducer,
  transaction: transactionSlice.reducer,
  program: programSlice.reducer,
  applicationProgramUpload: applicationProgramUpload.reducer,
  product: productSlice.reducer,
})
