import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { createLogger } from 'redux-logger'
import storage from 'redux-persist/lib/storage'

import { rootReducers } from './rootReducers'

const logger = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error,
})

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware, logger))

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'config'],
}

const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = createStore(persistedReducer, enhancer)
export const persistor = persistStore(store)
