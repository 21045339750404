import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchApplication = createAsyncThunk(
  'application/fetchApplication',
  async (_unused, { getState }) => {
    const { data } = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/applications`,
      headers: {
        Authorization: getState().auth.user?.token || '',
      },
    })
    return data
  }
)

export const updateApplication = createAsyncThunk(
  'application/updateApplication',
  async (formValues, { getState }) => {
    const { data } = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/applications/${getState().application?.data?.id}`,
      data: formValues,
      headers: {
        Authorization: getState().auth.user?.token || '',
      },
    })
    return data
  }
)

export const notifyAdmin = createAsyncThunk(
  'application/notifyAdmin',
  async (_unused, { dispatch, getState }) => {
    const { data } = await axios({
      url: `${process.env.REACT_APP_API_URL}/applications/notify-admin`,
      method: 'POST',
      headers: {
        Authorization: getState().auth.user?.token || '',
      },
    })
    dispatch(fetchApplication())
    return data
  }
)

export const handleNext = createAsyncThunk('application/handleNext', async (_unused, { getState }) => {
  const { data } = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/applications/next`,
    headers: {
      Authorization: getState().auth.user?.token || '',
    },
  })
  return data
})
export const fetchRegisterForm = createAsyncThunk(
  'application/fetchRegisterForm',
  async (applicationId, { getState }) => {
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/public/v2/register/single/${applicationId}`,
      headers: {
        Authorization: getState().auth.user?.token || '',
      },
    })
    return response.data
  }
)
export const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    isLoading: false,
    data: {},
    register: null,
  },
  reducers: {
    clearCourse: (state, action) => {
      state.course = []
    },
  },
  extraReducers: {
    [fetchApplication.fulfilled]: (state, action) => {
      state.data = action.payload
    },
    [updateApplication.pending]: (state, action) => {
      state.isLoading = true
    },
    [updateApplication.fulfilled]: (state, action) => {
      state.data = action.payload
      state.isLoading = false
    },
    [fetchRegisterForm.fulfilled]: (state, action) => {
      state.register = action.payload
    },
  },
})

// export const {clearCourse} = applicationSlice.actions
