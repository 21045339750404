import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const uploadTransaction = createAsyncThunk(
  'transaction/uploadTransaction',
  async (formValues, { getState }) => {
    const { data } = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/public/v2/transaction/upload/${
        getState().application?.data?.id
      }`,
      data: formValues,
      headers: {
        Authorization: getState().auth.user?.token || '',
      },
    })
    return data
  }
)

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {},
  reducers: {},
  extraReducers: {},
})
